/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.app_home{
	text-align: left;
}
.left-button, .right-button{
	display: flex;
	justify-content: center;
	align-items: none;
	flex-direction: row;
	color: #fff;
	background-color: #333;
	font-size: 2.7em;
	border-radius: 50%;
	position: absolute;
	width: 40px;
	height: 40px;
	cursor: pointer;
}

.left-button:hover,
.right-button:hover {
  color: black;
  background-color: #fff;
  border: 2px solid #333;
}

.left-button {
  top: 50%;
  left: 25px;
  transform: translate(0, -50%);
}
.right-button {
  top: 50%;
  right: 25px;
  transform: translate(0, -50%);
}

@media(max-width: 1200px){
	.left-button, .right-button{
		width: 35px;
		height: 35px;
		font-size: 2.3em;

	}
}

@media(max-width: 800px){
	.left-button, .right-button{
		width: 30px;
		height: 30px;
		font-size: 1.7em;
	}
}

@media(max-width: 500px){
	.left-button{
		left: 0px;
		width: 20px;
		height: 20px;
		font-size: 1.2em;
	}

	.right-button{
		right: 0px;
		width: 20px;
		height: 20px;
		font-size: 1.2em;
	}
}

.link_title{
	text-decoration:none;
}

.h3_padding{
	padding: 20px;
}

.accomplishmenth2{
	color: #60B5A9;
}

.helloTitleh2{
	color: #5E366A;
	text-align: center;
}

.bookrTitleh2{
	color: #FCFCFB;
	text-align: center;
}

.bookraccomplishmenth2{
	color: #0741AD;
}

.trainingAccomplishmenth2, .appTitle{
	color: #828a30;
}

.trainingTitleh2{
	color: #828a30;
	text-shadow:
		-1px -1px 0 #000,
		1px -1px 0 #000,
		-1px 1px 0 #000,
		1px 1px 0 #000;
}
