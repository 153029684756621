.contact_container{
  display: flex;
  margin-top: 50px;
}
.connect_h2{
  margin-top: 30px;
  text-align: center;
  font-size: 1.7em;
  font-family: "Gill Sans MT";
}
.message_Form{
  min-width: 600px;
  width: 50%;
}

.message_Form div img{
  display: block;
  text-align: center;
  margin: 0 auto
}

.formContainer{
  display: flex;
  width: 100%;
  flex-direction: column;
}

.contact_info{
  width: 45%;
  margin-left: 30px;
}

.form_label{
  margin: 10px auto;
  text-align: left;
  font-family: "Gill Sans MT";

}

.form_input{
  margin: 0px auto;
  width: 60%;
}

.form_button{
  width: 200px;
  padding: 10px 12px;
  border-radius: 10px;
  margin: 30px auto;
  background-color: #003a7c;
  color: #fff;
}

.socialIcon{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: 10px;
}

@media(max-width: 768px){
  .contact_container{
    flex-direction: column;
  }
  .message_Form{
    min-width: 500px;
    max-width: 760px;
    width: 100%;
  }
  .message_Form div img{
    margin-left: 0 auto;
  }

  .contact_info{
    max-width: 760px;
    width: 100%;
    margin-bottom: 50px;
    margin-left: 10px;
  }
}

@media(max-width: 414px){
  .message_Form{
    min-width: 400px;
  }
  .message_Form, .contact_info, .message_Form div img{
    max-width: 400px;
  }
}

@media(max-width: 375px){
  .message_Form{
    min-width: 375px;
  }
  .message_Form, .contact_info, .message_Form div img{
    max-width: 360px;
  }
}

@media(max-width: 320px){
  .message_Form{
    min-width: 315px;
  }
  .message_Form, .contact_info, .message_Form div img{
    max-width: 315px;
  }
}
