.about_me{
  width: 80%;
  margin: 10px auto;
}

.about_me h1{
  margin: 20px 0px;
  font-size: 1.7em;
  text-align: left;
}

.about_me a{
  text-decoration: none;
}

.about_me a:link, .about_me a:visited{
  color: #d44c4f;
}


.skillset{
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.skillset h1{
  font-size: 1.7em;
  text-align: center;
}

.top_skills{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.skill_icon{
  margin-top: 10px;
  width: 70px;
  height: 70px;
}

.other_skills{
  margin: 10px 0;
  font-size: 1.7em;
}

.skills_list{
  margin: 50px 0;
  width: 85%;
}

.languages{
  text-align: left;
}

.languages .title-start{
  background-color: #FFF;
}

.languages li{
  text-align: left;
  display: inline-block;
  padding: 8px 5px;
  background-color: #13D1E3;
  color: #000;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  border-color: #222;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: MontSerrat,Tahoma,Arial,sans-serif;
  font-weight: 700;
  font-size: .8em;
  line-height: 1em;
  box-shadow: 1px 1px 0 rgba(0,0,0,.25);
  margin: 3px;
  opacity: 1;
  cursor: grab;
  position: relative;
  overflow: hidden;
}
