.homewho{
  animation-name: slidein;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

.homewhat{
  animation-name: slidein;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.homewhere{
  animation-name: slidein;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.homeImg{
  animation-name: slide;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    color: white;
  }
  to {
    color: black;
  }
}

@keyframes slide {
  0% { width: 0%}
  25% { width: 25%}
  50% { width: 50%}
  75% {width: 75%}
  100% {width: 100%}
}
